import React from 'react'
import { css } from '@emotion/react'
import { StaticLayout } from '../components/StaticLayout'
import { Footer } from '../components/Footer'

const styles = css`
  body {
    padding: 0;
    margin: 0;
    /*gradient light color for faq section*/
    background-color: #fdfaf4;
  }

  #navbar {
    height: 50px;
    border-bottom: 2px solid #ccc;
    clear: both;
    background-color: #eee;
  }

  #logolink {
    float: left;
    height: 38px;
    margin-top: 7px;
    margin-left: 10px;
  }

  #logo {
    height: 38px;
  }

  #close {
    float: right;
    margin-right: 10px;
    margin-top: 7px;
    text-decoration: none;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: black;
    height: 40px;
    padding: 10px;
  }

  .static-content-background {
    padding: 0px 15px 15px 15px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4em;

    max-width: 700px;
    margin: auto;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #000066;
    cursor: default;
    font-size: 30px;
    font-weight: 500;
    margin-top: 10px;
  }

  h3 {
    font-size: 22px;
    line-height: 1.4em;
    font-weight: 500;
  }

  h5 {
    text-align: center;
    font-size: 14px;
  }
`
const TermsPage = () => (
  <StaticLayout>
    <div css={styles}>
      <div id="navbar">
        <a id="logolink" href="https://www.stayfari.com">
          <img
            id="logo"
            src="https://ik.imagekit.io/stayfari/general/tr:w-160,h-48,c-maintain_ratio/FL_Norwester_Elbaris_Hybrid-01_LlKpdcqksp.png?ik-sdk-version=javascript-1.4.3&updatedAt=1666155398553"
            alt="Stayfari Logo"
          />
        </a>
        <a id="close" href="javascript:window.close()">
          Close
        </a>
      </div>
      <div className="static-content-background">
        <h2>Terms of Service</h2>
        <h5>Last updated: December 5, 2022</h5>
        <h3>General</h3>
        <h4>Agreement</h4>
        <p>
          We, Team Stayfari, run a website called Stayfari, which is a platform where users find and
          book the best travel products and services.
        </p>
        <p>
          The following terms and conditions govern use of the Stayfari website and content,
          services and products available at or through the website, including, but not limited to,
          Stayfari.com and its sub-domains, (together, the &quot;Website&quot;). The Website is
          owned and operated by Stayfari, Inc. (“Stayfari”) and its subsidiaries, including Stayfari
          India. The Website is offered subject to your acceptance without modification of the terms
          and conditions contained herein and all other operating rules, policies and procedures
          that may be published (collectively, the “Agreement”).
        </p>
        <p>
          Please read this Agreement carefully before using the Website. By accessing or using any
          part of the Website, you agree to become bound by the terms and conditions of this
          agreement. If you do not agree to all the terms and conditions of this agreement, then you
          may not access the Website or use any services.
        </p>
        <p>
          You must be at least eighteen (18) years of age to use the Website. By using the Website
          and by agreeing to these terms and conditions, you warrant and represent that you are at
          least eighteen (18) years of age.
        </p>
        <p>
          The Website uses cookies. By using the Website and agreeing to these terms and conditions,
          you consent to Stayfari&quot;s use of cookies in accordance with the terms of
          Stayfari&quot;s privacy policy.
        </p>
        <h4>License to Use Website</h4>
        <p>
          Unless otherwise stated, Stayfari and/or its licensors own the intellectual property
          rights in the website and material on the website. Subject to the license below, all these
          intellectual property rights are reserved.
        </p>
        <p>
          You may view, download for caching purposes only, and print pages from the Website for
          your own personal use, subject to the restrictions set out below and elsewhere in these
          terms and conditions.
        </p>
        <p>
          You must not: (i) republish material from this website (including republication on another
          website); (ii) sell, rent or sub-license material from the Website; (iii) reproduce,
          duplicate, copy or otherwise exploit material on this website for commercial purpose; (iv)
          edit or otherwise modify any material on the website; or (v) redistribute material from
          this website (except for content specifically and expressly made available for
          redistribution)
        </p>
        <h4>Acceptable Use</h4>
        <p>
          You must not use the Website in any way that causes, or may cause, damage to the Website
          or impairment of the availability or accessibility of the Website; or in any way which is
          unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal,
          fraudulent or harmful purpose or activity.
        </p>
        <p>
          You must not use the Website to copy, store, host, transmit, send, use, publish or
          distribute any material which consists of (or is linked to) any spyware, computer virus,
          Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
        </p>
        <p>
          You must not conduct any systematic or automated data collection activities (including
          without limitation scraping, data mining, data extraction and data harvesting) on or in
          relation to the Website without Stayfari’s express written consent.
        </p>
        <p>
          You must not use this website to transmit or send unsolicited commercial communications.
        </p>
        <p>
          You must not use this website for any purposes related to marketing without Stayfari’s
          express written consent.
        </p>
        <h4>Restricted Access</h4>
        <p>
          Access to certain areas of the Website is restricted. Stayfari reserves the right to
          restrict access to other areas of the Website, or this entire website, at Stayfari’s sole
          discretion.
        </p>
        <p>
          If Stayfari provides you with a user ID and password to enable you to access restricted
          areas of the Website or other content or services, you must ensure that your user ID and
          password are kept confidential.
        </p>
        <p>
          Stayfari may disable your user ID and password in Stayfari’s sole discretion without
          notice or explanation.
        </p>
        <h3>Other</h3>
        <h4>Changes</h4>
        <p>
          Stayfari reserves the right, at its sole discretion, to modify any part of this Agreement.
          It is your responsibility to check this Agreement periodically for changes. Your continued
          use of the Website following the posting of any changes to this Agreement constitutes
          acceptance of those changes. Stayfari may also, in the future, offer new features and/or
          services, which shall be subject to the terms and conditions of this Agreement.
        </p>
        <h4>Termination</h4>
        <p>
          Stayfari may terminate your access to the Website at any time, with or without cause or
          notice, effective immediately. If you wish to terminate this Agreement or your Stayfari
          account, you may simply discontinue using the Website. All provisions of this Agreement,
          which by their nature should survive termination, including, without limitation, ownership
          provisions, warranty disclaimers, indemnity and limitations of liability.
        </p>
        <h4>Warranties</h4>
        <p>
          The Website is provided “as is”. Stayfari and its associates hereby disclaim all
          warranties of any kind, express or implied, including, without limitation, the warranties
          of merchantability, fitness for a particular purpose and non-infringement. Neither
          Stayfari nor its associates, make any warranty that access to the Website will be
          continuous or uninterrupted. You understand you use the Website at your own discretion and
          risk.
        </p>
        <h4>Liability</h4>
        <p>
          In no event will Stayfari, or its suppliers or licensors, be liable with respect to any
          subject matter of this agreement under any contract, negligence, strict liability or other
          legal or equitable theory for: (i) any special, incidental or consequential damages; (ii)
          the cost of procurement for substitute products or services; (iii) for interruption of use
          or loss or corruption of data; or (iv) for any amounts that exceed the fees paid by you to
          Stayfari under this agreement. Stayfari shall have no liability for any failure or delay
          due to matters beyond their reasonable control.
        </p>
        <p>
          Nothing in the Website disclaimer will exclude or limit any warranty implied by law that
          it would be unlawful to exclude or limit; and nothing in the Website disclaimer will
          exclude or limit Stayfari’s liability in respect of any: (i) fraud or fraudulent
          misrepresentation on the part of Stayfari; or (ii) matter which would be illegal or
          unlawful for Stayfari to exclude or limit, or to attempt or purport to exclude or limit,
          its liability.
        </p>
        <h4>Reasonableness</h4>
        <p>
          By using the Website, you agree that the exclusions and limitations of liability set out
          in the Website disclaimer are reasonable.
        </p>
        <p>If you do not think they are reasonable, you must not use this website.</p>
        <h4>Other Parties</h4>
        <p>
          You accept that, as a limited liability entity, Stayfari has an interest in limiting the
          personal liability of its officers and employees. You agree that you will not bring any
          claim personally against Stayfari’s officers or employees in respect to any losses you
          suffer in connection with the Website.
        </p>
        <p>
          Without prejudice to the foregoing paragraph, you agree that the limitations of warranties
          and liability set out in the Website disclaimer will protect Stayfari’s officers,
          employees, agents, subsidiaries, successors, assigns, and sub-contractors as well as
          Stayfari.
        </p>
        <h4>Unenforceable Provisions</h4>
        <p>
          If any provision of the Website disclaimer is, or is found to be, unenforceable under
          applicable law, that will not effect the enforceability of the other provisions of the
          Website terms and conditions.
        </p>
        <h4>Representation</h4>
        <p>
          You represent and warrant that (i) your use of the Website will be in strict accordance
          with this Agreement and with all applicable laws and regulations (including without
          limitation any local laws or regulations in your country, regarding online conduct and
          acceptable content, and including all applicable laws regarding the transmission of
          technical data exported from the country in which you reside) and (ii) your use will not
          infringe or misappropriate the intellectual property rights of any third party.
        </p>
        <h4>Content</h4>
        <p>
          By submitting any information (including without limitation text, images, audio material,
          video material and audio-visual material) (&quot;Content&quot;) to Stayfari for inclusion
          on the Website, you grant Stayfari a world-wide, irrevocable, royalty-free, and
          non-exclusive license to use, reproduce, adapt, publish, translate, modify and distribute
          the Content in any existing or future media. You also grant to Stayfari the right to
          sub-license these rights and the right to bring an action for infringement of these
          rights. If you want Content removed, Stayfari will assess the impact on the system and the
          time involved and remove content at will at its own full discretion.
        </p>
        <p>
          Your Content must not be illegal or unlawful, must not infringe any third party’s legal
          rights, and must not be capable of giving rise to legal action whether against you or
          Stayfari or a third party (in each case under any applicable law)
        </p>
        <p>
          You must not submit any Content to the Website that is or has ever been the subject of any
          threatened or actual legal proceedings or other similar complaint.
        </p>
        <p>
          Stayfari reserves the right to edit or remove any material submitted to the Website, or
          stored on Stayfari’s servers, or hosted or published upon the Website.
        </p>
        <p>
          Notwithstanding Stayfari’s rights under these terms and conditions in relation to Content,
          Stayfari does not undertake to monitor the submission of such content to, or the
          publication of such content on, the Website.
        </p>
        <h4>Indemnity</h4>
        <p>
          You hereby indemnify Stayfari and undertake to keep Stayfari indemnified against any
          losses, damages, costs, liabilities and expenses (including without limitation legal
          expenses and any amounts paid by Stayfari to a third party in settlement of a claim or
          dispute on the advise of Stayfari’s legal advisers) incurred or suffered by Stayfari
          arising out of any breach by you of any provision of these terms and conditions, or
          arising out of any claim that you have breached any provision of these terms and
          conditions.
        </p>
        <h4>Breach of Website Terms and Conditions</h4>
        <p>
          Without prejudice to Stayfari’s other rights under these terms and conditions, if you
          breach these terms and conditions in any way, Stayfari may take such action as Stayfari
          deems appropriate to deal with the breach, including suspending your access to the
          Website, prohibiting you from accessing the Website, blocking computers using your IP
          address from accessing the Website, contacting your internet service provider to request
          that they block your access to the Website and/or bringing court proceedings against you.
        </p>
        <h4>Assignment</h4>
        <p>
          Stayfari may transfer, sub-contract or otherwise deal with Stayfari’s rights and/or
          obligations under these terms and conditions without notifying you or obtaining your
          consent.
        </p>
        <p>
          You may not transfer, sub-contract or otherwise deal with your rights and/or obligations
          under theses terms and conditions.
        </p>
        <h4>Severability</h4>
        <p>
          If a provision of these terms and conditions is deemed by any court or other competent
          authority to be unlawful and/or unenforceable, the other provisions will continue in
          effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if
          part of it were deleted, that part will be deemed to be deleted, and the rest of the
          provision will continue in effect.
        </p>
        <h4>Entire Agreement</h4>
        <p>
          These terms and conditions constitute the entire agreement between you and Stayfari in
          relation to your use of the Website, and supersede all previous agreements in respect of
          your use of the Website.
        </p>
        <h4>Law and Jurisdiction</h4>
        <p>
          These terms and conditions will be governed by and construed in accordance with India Law,
          and any disputes relating to these terms and conditions will be subject to the
          non-exclusive jurisdiction of the courts of India.
        </p>
      </div>
    </div>
    <Footer />
  </StaticLayout>
)

export default TermsPage
